import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { APIURL } from '../Global';

function ForgotPasswordComponent() {
  const [mobile, setMobile] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');
    setSuccess('');

    if (!mobile) {
      setError('Please provide a mobile number.');
      return;
    }

    const requestBody = { mobile };

    try {
      const response = await fetch(`${APIURL}/backend/api/otp-reset-password/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();

      if (data.status === 'success') {
        setSuccess(data.message);
        setTimeout(() => {
          navigate('/verify-otp', { 
            state: { 
              identifier: mobile, 
              identifierType: 'mobile' 
            } 
          });
        }, 2000);
      } else {
        setError(data.message);
      }
    } catch (error) {
      setError('An error occurred. Please try again.');
    }
  };

  return (
    <>
      <Container>
        <Form onSubmit={handleSubmit}>
          <Title>Forgot Password</Title>
          <Subtitle>Enter your mobile number to reset your password</Subtitle>

          <InputWrapper>
            <Input
              type="tel"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              placeholder="Enter your mobile number"
            />
          </InputWrapper>

          {error && <ErrorMessage>{error}</ErrorMessage>}
          {success && <SuccessMessage>{success}</SuccessMessage>}

          <SubmitButton type="submit">Send OTP</SubmitButton>
        </Form>
      </Container>
      <Footer>
        <div style={{ fontWeight: 'bold', marginLeft:'20px' }}>CodeDC</div>
        <div>Copyright@2024 IntPurple Technologies LLP.</div>
        <div style={{ marginRight:'20px' }}>Ver:V1.2.1</div>
      </Footer>
    </>
  );
}

const Footer = styled.footer`
  background-color: #0C2340;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 15px;
  width: 100%;
  position: relative;
  bottom: 0;
  text-align: center;
  color: #fff;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fff;
`;

const Form = styled.form`
  background-color: #f5f5f5;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
  width: 100%;
  max-width: 400px;
`;

const Title = styled.h1`
  text-align: center;
  color: #073980;
  margin-bottom: 20px;
`;

const Subtitle = styled.p`
  text-align: center;
  color: #666;
  margin-bottom: 30px;
`;

const InputWrapper = styled.div`
  margin-bottom: 20px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
`;

const SubmitButton = styled.button`
  margin-top: 5px;
  padding: 8px 30px;
  font-size: 24px;
  font-family: "Inter", sans-serif;
  color: #fff;
  background-color: #1976d2;
  border: 1px solid rgba(93, 93, 93, 1);
  border-radius: 12px;
  cursor: pointer;
  @media (max-width: 991px) {
    padding: 20px;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  text-align: center;
  margin-bottom: 20px;
`;

const SuccessMessage = styled.p`
  color: green;
  text-align: center;
  margin-bottom: 20px;
`;

export default ForgotPasswordComponent;