import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import { APIURL } from '../Global';
import styled from "styled-components";
import './DetailsPage.css';

function IndividualDetailsPage() {
  const location = useLocation();

  // Extract the query parameters from the URL
  const query = new URLSearchParams(location.search);
  const individualId = query.get('individualId');
  const token = query.get('token');

  const [individualDetails, setIndividualDetails] = useState(null);
  const [isHouseModalOpen, setIsHouseModalOpen] = useState(false); // Modal state for house details

  // Function to open the house modal.
  const openHouseModal = () => {
    setIsHouseModalOpen(true);
  };

  // Function to close the house modal
  const closeHouseModal = () => {
    setIsHouseModalOpen(false);
  };

  const DetailItem = ({ label, value }) => (
    <div className="detail-item">
      <span className="detail-label"><b>{label}:</b></span>
      <span className="detail-value">{value || 'N/A'}</span>
    </div>
  );


  const DetailItemPop = ({ label, value }) => (
    <div className="detail-item-pop">
      <span className="detail-label-pop"><b>{label}:</b></span>
      <span className="detail-value-pop">{value || 'N/A'}</span>
    </div>
  );

  const HeaderTitle = styled.h1`
  color: #2456ae;
  margin: 0;
  font-size: 18px;
`;
const HeaderLogo = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 50px;
    margin-right: 5px;
  }
`;

  useEffect(() => {
    const fetchIndividualDetails = async () => {
      try {
        const response = await fetch(`${APIURL}/data_collection/api/individual-details/?individual_id=${individualId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Token ${token}`,
          },
        });
        const data = await response.json();
        if (response.ok && data.status === 'success') {
          setIndividualDetails(data.data);
        } else {
          console.error('Failed to fetch individual details.');
        }
      } catch (error) {
        console.error('Error fetching individual details:', error);
      }
    };

    if (individualId && token) {
      fetchIndividualDetails();
    }
  }, [individualId, token]);

  const capitalizeFirstLetter = (string) => {
    if (typeof string !== 'string') {
      return ''; 
    }
    return string
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  return (
    <>
    <div className="page-container">
   
    <nav className="left-navbar">
    <HeaderLogo>
            <img src="/images/company-logo.png" alt="Company Logo" />
            <HeaderTitle>CODEDC</HeaderTitle>
          </HeaderLogo>
    </nav>
    <div className="details-container">
      <h1 style={{ fontWeight: 'bold', color: '#041E42',fontSize:18 }}>Individual Details</h1>
      {individualDetails ? (
        <div className="details-grid">
       <DetailItem label="Name" value={capitalizeFirstLetter(individualDetails.name) || 'N/A'} />
       <DetailItem label="Guardian Name" value={individualDetails.guardian_name || 'N/A'} />
       <DetailItem label="Serial Number" value={individualDetails.sl_no || 'N/A'} />
       <DetailItem label="Date Of Birth" value={individualDetails.dob || 'N/A'} /> 
       <DetailItem label="Age" value={individualDetails.age || 'N/A'} /> 
  <DetailItem label="Gender" value={individualDetails.gender || 'N/A'} />
  <DetailItem label="Epic Number" value={individualDetails.epic_no || 'N/A'} />
  <DetailItem label="Polling Booth Number" value={individualDetails.polling_booth_number || 'N/A'} />
  <DetailItem label="Polling Booth Name" value={individualDetails.polling_booth_name || 'N/A'} />
  {/* <DetailItem label="Email" value={individualDetails.email || 'N/A'} />
  <DetailItem label="Date Of Birth" value={individualDetails.dob || 'N/A'} /> */}

  {/* <DetailItem label="Aadhaar Number" value={individualDetails.aadhaar_number || 'N/A'} /> */}

  
  {/* <DetailItem label="Guardian Relation" value={individualDetails.guardian_relation || 'N/A'} /> */}
  {/* <DetailItem label="House Number" value={individualDetails.house_no || 'N/A'} /> */}

  <DetailItem 
    label="House Number" 
    value={
      <button onClick={openHouseModal} className="clickable-button">
        {individualDetails.house_no || 'N/A'}  
      </button>
    } 
  />
  
  <DetailItem label="House Name" value={individualDetails.house_name || 'N/A'} />
  <DetailItem label="Education" value={individualDetails.education || 'N/A'} />
  <DetailItem label="Profession" value={individualDetails.profession || 'N/A'} />
  <DetailItem label="Mobile Number" value={individualDetails.mobile_number || 'N/A'} />
  <DetailItem label="Political Affiliation Party" value={individualDetails.political_affiliation_party || 'N/A'} />
  <DetailItem label="Political Affiliation Status" value={individualDetails.political_affiliation_status || 'N/A'} />
  <DetailItem label="Influencing factor" value={individualDetails.political_affiliation_reason || 'N/A'} />
  <DetailItem label="Religion" value={individualDetails.religion || 'N/A'} />
  <DetailItem label="Caste" value={individualDetails.caste || 'N/A'} />
  <DetailItem label="Sub caste" value={individualDetails.sub_caste || 'N/A'} />
  <DetailItem label="Religious Organizations" value={individualDetails.religious_organizations || 'N/A'} />
  {/* <DetailItem label="Occupation" value={individualDetails.occupation || 'N/A'} />
  <DetailItem label="Marital Status" value={individualDetails.marital_status || 'N/A'} />
  <DetailItem label="Vehicles" value={individualDetails.vehicles || 'N/A'} />
  <DetailItem label="Differently Abled" value={individualDetails.differently_abled || 'N/A'} />
  <DetailItem label="Chronic Disease" value={individualDetails.chronic_disease || 'N/A'} /> */}

  {/* <DetailItem label="Social Organizations" value={individualDetails.social_organizations || 'N/A'} /> */}
 
  {/* <DetailItem label="Pension" value={individualDetails.pension ? 'Yes' : 'No'} /> */}
  <DetailItem label="Eligible To Vote" value={individualDetails.eligible_to_vote ? 'Yes' : 'No'} />
  {/* <DetailItem label="Expatriate" value={individualDetails.expatriate || 'N/A'} /> */}
  {/* <DetailItem label="Social Insurance" value={individualDetails.social_insurance ? 'Yes' : 'No'} />
  <DetailItem label="Health Insurance" value={individualDetails.health_insurance ? 'Yes' : 'No'} />
  <DetailItem label="Welfare Fund" value={individualDetails.welfare_fund || 'N/A'} />
  <DetailItem label="Is Student" value={individualDetails.is_student ? 'Yes' : 'No'} /> */}
  {/* <DetailItem label="Field of Education" value={individualDetails.field_of_education || 'N/A'} /> */}
  {/* <DetailItem label="Profession" value={individualDetails.profession || 'N/A'} /> */}
  {/* <DetailItem label="Active on Social Media" value={individualDetails.is_active_on_social_media ? 'Yes' : 'No'} />
  <DetailItem label="Most Active Social Media" value={individualDetails.most_active_social_media || 'N/A'} /> */}
  <DetailItem label="Remarks" value={individualDetails.remarks || 'N/A'} />

          {/* Modal for displaying house details. */}
          <Dialog open={isHouseModalOpen} onClose={closeHouseModal} maxWidth="md">
          <DialogTitle style={{textAlign:'center',fontWeight:'bold',color:'#041E42',fontSize:16}}>Details of House Number  {individualDetails?.household?.house_no} And House Name  {individualDetails?.household?.house_name}
              <IconButton
                aria-label="close"
                onClick={closeHouseModal}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={6}><DetailItemPop label="House No" value={individualDetails?.household?.house_no || 'N/A'} /></Grid>
                <Grid item xs={6}><DetailItemPop label="House Name" value={capitalizeFirstLetter(individualDetails?.household?.house_name )|| 'N/A'} /></Grid>
                <Grid item xs={6}><DetailItemPop label="Ration Card Type" value={individualDetails?.household?.ration_card_type || 'N/A'} /></Grid>
                <Grid item xs={6}><DetailItemPop label="Ration Card Number" value={individualDetails?.household?.ration_card_number || 'N/A'} /></Grid>
                <Grid item xs={6}><DetailItemPop label="Electricity" value={individualDetails?.household?.electricity ? 'Yes' : 'No'} /></Grid>
                <Grid item xs={6}><DetailItemPop label="Special Treatment" value={individualDetails?.household?.special_treatment || 'N/A'} /></Grid>
                <Grid item xs={6}><DetailItemPop label="Agriculture Details" value={individualDetails?.household?.agriculture_details || 'N/A'} /></Grid>
                <Grid item xs={6}><DetailItemPop label="Pets" value={individualDetails?.household?.pets || 'N/A'} /></Grid>
                <Grid item xs={6}><DetailItemPop label="Land" value={individualDetails?.household?.land ? 'Yes' : 'No'} /></Grid>
                <Grid item xs={6}><DetailItemPop label="House Status" value={individualDetails?.household?.house_status || 'N/A'} /></Grid>
                <Grid item xs={6}><DetailItemPop label="Drinking Water Source"value= {individualDetails?.household?.drinking_water_source || 'N/A'} /></Grid>
                <Grid item xs={6}><DetailItemPop label="Toilet" value={individualDetails?.household?.toilet ? 'Yes' : 'No'} /></Grid>
                <Grid item xs={6}><DetailItemPop label="Way To House" value={individualDetails?.household?.way_to_house ? 'Yes' : 'No'} /></Grid>
                {/* <Grid item xs={6}><DetailItemPop label="Waste Management" value={individualDetails?.household?.waste_management || 'N/A'} /></Grid> */}
                {/* <Grid item xs={6}><DetailItemPop label="Road Access" value={individualDetails?.household?.road_access || 'N/A'} /></Grid> */}
              
                <Grid item xs={6}><DetailItemPop label="Remarks" value={individualDetails?.household?.remarks || 'N/A'} /></Grid>
              </Grid>
            </DialogContent>
          </Dialog>

        </div>
      ) : (
        <p style={{ color: '#073980' }}>Loading...</p>
      )}
      </div>
    </div>
    <Footer>
        <div style={{ fontWeight: 'bold',marginLeft:'20px' }}>CodeDC</div>
        <div>Copyright@2024 IntPurple Technologies LLP.</div>
        <div style={{ marginRight:'20px' }} >Ver:V1.2.1</div>
      </Footer>
  </>
  );
}
const Footer = styled.footer`
  background-color: #1877F21A;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 15px;
  width: 100%;
  position: relative;
  bottom: 0;
  text-align: center;
  color: #073980;
  margin:auto;
`;

export default IndividualDetailsPage;
